<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";

import {
  required
} from "vuelidate/lib/validators";

/**
 * Form Validation component
 */
export default {
  page: {
    title: "Form Create Role",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Form Create Role",
      items: [
        {
          text: "Home",
          href: "/home"
        },
        {
          text: "Roles",
          href: "/role"
        },
        {
          text: "Create Role",
          active: true
        }
      ],
      form: {
        name: null
      },
      typesubmit: false,
      isError: false,
      errorMessage: null
    };
  },
  validations: {
    form: {
      name: { required }
    }
  },
  methods: {
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    onSubmit(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isError = true
        this.errorMessage = "please fill the form with valid data"
      } else {
        // do your submit logic here
        let param = new FormData();
        param.append('name', this.form.name)

        this.$axios.post('/role', param)
        .then( (result) => { 
          console.log(result.data)
          Swal.fire("Success", "Role Created Successfully", "success");
          this.$router.push('/role')
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-model="isError"
              variant="danger"
              class="mt-3"
              dismissible
            >{{errorMessage}}</b-alert>
            <form action="#" @submit.prevent="onSubmit">
              <div class="form-group">
                <label>Name</label>
                <input
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  name="name"
                  :class="{ 'is-invalid': typesubmit && $v.form.name.$error }"
                />
                <div v-if="typesubmit && $v.form.name.$error" class="invalid-feedback">
                  <span v-if="!$v.form.name.required">This value is required.</span>
                </div>
              </div>

              <div class="form-group mb-0">
                <div>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>